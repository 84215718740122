.modalOverlay {
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: end;
  opacity: 0;
  z-index: 1000;
  transition:
    opacity 0.3s ease,
    background-color 0.3s ease;
  background-color: transparent;
  height: 100dvh;
  pointer-events: none;

  &[data-is-open="true"] {
    opacity: 1;
    background-color: var(--cosmos-color-surface-glass-dark-60);
    overflow: clip;
    pointer-events: auto;
  }
}

.flyOverContainer {
  display: flex;
  align-items: end;
  transform: translateY(100dvh);
  transition: transform 0.3s ease-in-out;
  width: 100%;
  height: 100%;

  &[data-is-open="true"] {
    transform: translate(0);
  }

  @media (width >= 1000px) {
    justify-content: end;
    transform: translate(100%, 0);
    transition: transform 0.3s ease-in-out;
    margin-left: auto;
    width: 100%;

    &[data-is-open="true"] {
      transform: translateX(0);
    }
  }
}
