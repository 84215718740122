/** 
 * This is a widely used component, 
 * we will need to add overwritable CSS variables as we go...
 */

/**
 * 1. We often embed the ButtonWrapper, so set some global values
 */
.container {
  --_text-align: var(--teaser-basic-text-align, center);
  --_justify-content: var(--teaser-basic-justify-content, center); /* 1 */
  --_padding-inline: var(--teaser-basic-padding-inline, initial);

  padding-inline: var(--_padding-inline);
  width: 100%;
  text-align: var(--_text-align);

  @media (width >= 1000px) {
    --_text-align: var(--teaser-basic-text-align, start);
    --_justify-content: var(--justify-content, start); /* 1 */
    --_padding-inline: var(
      --teaser-basic-padding-inline,
      var(--cosmos-spacing-xx-loose, 40px)
    );
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: var(--teaser-basic-header-gap, 16px);

  /* Source order, kicker should be under the h2 title in source, but visually above it */
  &[data-heading="title"] {
    flex-direction: column-reverse;
  }
}

.content {
  margin-top: var(--teaser-basic-content-top-margin, 24px);
}
