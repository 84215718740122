.container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: var(--spacing-x-loose);
  border-top-right-radius: var(--cosmos-radius-large);
  border-top-left-radius: var(--cosmos-radius-large);
  background-color: var(--color-surface-solid-light-lighter);
  padding: var(--spacing-normal);
  width: 100%;
  height: 90%;

  @media (width >= 1000px) {
    border-top-right-radius: 0;
    border-top-left-radius: var(--cosmos-radius-large);
    border-bottom-left-radius: var(--cosmos-radius-large);
    padding: var(--spacing-x-loose);
    max-width: 741px; /* magic number from Figma */
    height: 100%;
  }
}

.featuredProducts {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-normal);

  @media (width >= 650px) {
    flex-direction: row;
  }
}

.featuredProduct__image {
  position: relative;
  margin-top: var(
    --spacing-loose
  ); /* Aspect ratio of the can taken from figma */
  width: auto;
  height: 101px; /* magic number from Figma */

  @media (width >= 650px) {
    height: 154px; /* magic number from Figma */
  }
}

.featuredProduct__image,
.image {
  transition: transform 0.25s ease;
  /* Values taken from Figma */
  aspect-ratio: 61 / 161;
  height: 100px;

  @media (width >= 650px) {
    height: 161px; /* magic number from Figma */
  }

  .featuredProduct:hover &,
  .productSquare:hover & {
    transform: translateY(-2px);
  }
}

.title {
  flex: 1;
}

.featuredProduct__inner {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-normal);
  width: 100%;
}

.featuredProduct {
  all: unset;
  border-radius: var(--cosmos-radius-large);
  background-color: var(--productBackgroundColor);
  padding: var(--spacing-normal);
  height: 76px; /* magic number from Figma */
  overflow: clip;

  @media (width >= 650px) {
    flex: 1;
    padding-inline-end: var(--spacing-xx-loose);
    height: 122px; /* magic number from Figma */
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.productsCategory {
  all: unset;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.productSquare {
  --_productSquare-size: 76px; /* magic number from Figma */

  display: flex;
  position: relative;
  justify-content: center;
  align-items: centers;
  border-radius: var(--cosmos-radius-large);
  background-color: var(--productBackgroundColor);
  padding-top: var(--spacing-normal);
  width: var(--_productSquare-size);
  height: var(--_productSquare-size);
  overflow: clip;

  @media (width >= 650px) {
    --_productSquare-size: 122px; /* magic number from Figma */
  }
}

.productsSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacing-tight);
  margin-top: var(--spacing-normal);

  @media (width >= 650px) {
    gap: var(--spacing-normal);
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inner {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-normal);
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
}
