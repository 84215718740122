/* START Benefits */

.benefits-container {
  --teaser-basic-text-align: start;
  --teaser-basic-padding-inline: 0;

  /*grid-row-start: 3;
  grid-column-start: 1;
  grid-column-end: -1;*/
  display: flex;
  position: relative;
  padding-inline: var(--spacing-sides);
  overflow: clip; /* stop sticky can peaking out at bottom on mobile */

  @media (width >= 1000px) {
    min-height: 150lvh;
  }

  /*@media (height >= 1200px) {
    min-height: 100lvh;
  }*/
}

.benefits-inner {
  margin-inline: auto;
  width: 100%;
  max-width: var(--max-width-revamp-base);

  @media (width >= 1000px) {
    display: flex;
  }
}

.benefits-layout {
  display: flex;
  flex-direction: column-reverse;

  @media (width >= 1000px) {
    display: grid;
    position: relative;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.benefits-content {
  display: flex;
  grid-column-start: 2;
  flex-direction: column;
  gap: var(--spacing-xx-loose);
  z-index: 2;
  margin-block: initial;
  margin-left: calc(50% - 50vw);
  border-top-right-radius: var(--radius-x-large);
  border-top-left-radius: var(--radius-x-large);
  background-color: var(--cosmos-color-surface-solid-light-lighter);
  padding-inline: var(--spacing-sides);
  padding-block: var(--spacing-xxxx-loose);
  width: 100vw;

  @media (width >= 1000px) {
    display: flex;
    position: sticky;
    top: 0;
    grid-row: 1;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
    margin-left: initial;
    margin-block: initial;
    background-color: initial;
    padding-inline: initial;
    padding-inline: var(--cosmos-spacing-xx-loose, 40px);
    padding-block: initial;
    padding-block: var(--spacing-xxxx-loose);
    width: initial;
    min-height: 100lvh;
  }
}

.benefits-list {
  display: flex;
  row-gap: var(--spacing-x-loose);
  flex-direction: column;

  @media (width >= 650px) {
    row-gap: var(--spacing-xx-loose);
  }
}

.benefits-point {
  --prose-font-size: var(--fluid-type-medium);
  --prose-line-height: var(--type-medium-line-height);

  display: flex;
  row-gap: var(--spacing-x-tight);
  flex-direction: column;
}

/* END Benefits */

.media {
  --_can-shadow: var(
    --scheme-can-shadow-rgb,
    var(--can-subtle-shadow-color-rgb)
  );
  --_background-color: var(--scheme-background);

  display: flex;
  position: relative;
  position: sticky;
  top: 0;
  grid-row: 1;
  grid-column-start: 1;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  z-index: 1;
  margin-bottom: -16px;
  margin-left: calc(50% - 50vw);
  background-color: var(--_background-color);
  padding-top: var(--spacing-xxxx-loose);
  width: 100vw;
  overflow: hidden;

  @media (width >= 1000px) {
    display: grid;
    position: sticky;
    top: 0;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    margin-bottom: initial;
    margin-left: initial;
    background-color: initial;
    padding-top: initial;
    width: initial;
    min-height: 100lvh;
  }
}

.img {
  display: block;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  filter: drop-shadow(
    var(--can-subtle-shadow-values)
      rgb(var(--_can-shadow) / var(--can-subtle-shadow-alpha))
  );
  margin-inline: auto;
  max-width: 230px;
  height: 352px;
  object-fit: cover;
  object-position: center top;

  @media (width >= 1000px) {
    transform: translateY(5%);
    max-width: 230px;
    height: auto;
  }
}

/*.img-wrap {
  overflow-y: clip;
  margin-inline: auto;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  background: red;
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0.5;
  height: 390px;
}*/

.square {
  display: none;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  will-change: width, height, transform;
  margin-inline: auto;
  border-radius: var(--radius-x-large, 16px);
  background-color: var(--_background-color);
  width: 100%;
  max-width: 360px;
  height: 390px;

  &[expanded="true"] {
    max-width: 456px;
    height: 536px;
  }

  @media (width >= 1000px) {
    display: block;
  }
}

.can-mask-sticky-desktop {
  display: none;
  position: sticky;
  top: 0;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  pointer-events: none;

  @media (width >= 1000px) {
    /* padding-block: 32px; */
    display: flex;
  }
}

.can-mask-spacer-desktop {
  flex-grow: 1;
  visibility: hidden;
  width: 100%;
}

.can-mask-square-desktop {
  visibility: hidden;
  will-change: width, height, transform;
  border-radius: var(--radius-x-large, 16px);
  /* background-color: pink; */
  width: 100%;
  max-width: 360px;
  height: 390px;
}

.can-mask-desktop {
  position: relative;
  flex-grow: 1;
  width: 100%;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: var(--cosmos-color-surface-solid-light-lighter);
    width: 100%;
    content: "";

    /*  @media (width >= 1350px) {
      bottom: -32px;
    }*/
  }
}
