.kicker {
  font-weight: var(--font-weight-heading);
  font-size: var(--fluid-type-small);
  line-height: var(--type-small-line-height);
  font-family: var(--ff-bull);
  text-transform: uppercase;

  &[appearance="light"] {
    color: var(--color-text-light);
  }

  &[appearance="dark"] {
    color: var(--color-text-dark);
  }

  &[kind="subtle"][appearance="light"] {
    color: var(--color-text-light-subtle);
  }

  &[kind="subtle"][appearance="dark"] {
    color: var(--color-text-dark-subtle);
  }
}
