/* Anything in this file that isn't a Cosmos CSS variable is here to reduce FOUC issues before COSMOS loads */

/**
 * 1. reduce preload FOUC
 */
.button {
  /* This should override any defaults, so go last  */
  &[data-custom-color="white"] {
    color: var(--cosmos-color-text-dark); /* 1 */

    --cosmos-button-border: var(--border-thin);

    /* default styles */
    --cosmos-button-background: var(--color-surface-solid-light-lighter);
    --cosmos-button-color: var(--cosmos-color-text-dark);
    --cosmos-button-border-color: var(--color-surface-solid-light-lighter);

    /* hover styles */
    --cosmos-button-background-hover: var(--color-surface-glass-light-45);
    --cosmos-button-border-color-hover: var(--color-surface-glass-light-45);
    --cosmos-button-color-hover: var(--cosmos-color-text-dark);
  }
}
