/* TODO: A lot of this CSS can be refactored  */
.container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  margin-top: var(--spacing-top);
  margin-bottom: var(--spacing-bottom);
  width: 100%;
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 24px;
  margin-inline: auto;
  padding-inline: var(--spacing-normal);
  max-width: var(--max-width-revamp-base);

  @media (width >= 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding-inline: 0;
  }
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;

  @media (width >= 1000px) {
    grid-column: 1;
    height: 100%;

    [data-alignment="reverse"] & {
      grid-row: 1;
      grid-column: 2;
    }
  }
}

.right {
  --_scheme-background: var(
    --scheme-background,
    var(--color-surface-solid-dark)
  );
  --_can-shadow: var(--scheme-can-shadow-rgb, var(--can-hero-shadow-color-rgb));

  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /*  z-index: 2;*/
  width: 100%;
  height: 100%;

  @media (width >= 1000px) {
    display: flex;
    grid-column: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    [data-alignment="reverse"] & {
      grid-row: 1;
      grid-column: 1;
    }
  }
}

.cards {
  display: grid;
  grid-template-rows: 1fr 52px;
  grid-template-columns: 1fr;
  z-index: 1;
  width: 100%;
  max-width: 480px;
}

.label {
  --cosmos-text-color: var(--color-text-light);

  border-bottom-right-radius: var(--cosmos-radius-large);
  border-bottom-left-radius: var(--cosmos-radius-large);
  background-color: var(--cosmos-color-surface-glass-dark-60);
  padding-inline: var(--cosmos-spacing-loose, 24px);
  padding-block: var(--cosmos-spacing-x-tight, 4px);
}

.image {
  width: 240px;
}

@media (width <= 1000px) {
  [data-clicked="true"] .img-mask-box-bg {
    margin: 24px;
  }
}

@media (width >= 1000px) {
  .cards:hover .img-mask-box-bg {
    margin: 24px;
  }
}

.img-mask-box-bg {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: start;
  z-index: 1;
  transition: margin 0.25s linear;
  margin: 0;
  box-shadow: var(--cosmos-elevation-1-above);
  border-radius: var(--radius-x-large);
  background-color: var(--_scheme-background);
  overflow: hidden;
}

.img-mask-box {
  display: flex;
  position: relative;
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  width: 100%;
  max-width: 480px;
  height: 100%;
  max-height: 530px;
  overflow: hidden;
}

.banderole-text-wrap {
  display: flex; /* help with inner text overflow */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  inset-inline-start: 50%;
  width: 100%;
}

.banderole-text-wrap-inner {
  position: relative;
  inset-inline-start: 50%;
}

/* 1. Cosmos title has it's own internal --cosmos-title-font-family so we need to set it elsewhere */
.banderole-text {
  --cosmos-title-font-size: var(--cosmos-type-xxxx-large-font-size-desktop);
  --cosmos-title-font-weight: var(--cosmos-font-weight-bold);
  --cosmos-title-color: var(--_scheme-background, transparent);
  --cosmos-title-font-family: var(--_font-family-title); /* 1 */

  overflow: hidden;
  white-space: nowrap;
  -webkit-text-stroke-width: 2px; /* adds a visual 2px stroke */
  -webkit-text-stroke-color: var(--cosmos-color-text-dark);
  paint-order: stroke fill;

  &[data-appearance="dark"] {
    -webkit-text-stroke-color: var(--cosmos-color-text-dark);
  }

  &[data-appearance="light"] {
    -webkit-text-stroke-color: var(--cosmos-color-text-light);
  }
}

.can-wrap {
  position: relative;
  transform: translateY(5%);
  z-index: 1;
  margin-top: 80px;
}

@media (width <= 1000px) {
  [data-clicked="true"] .product-name {
    z-index: 4;
  }
}

@media (width >= 1000px) {
  .cards:hover .product-name {
    z-index: 4;
  }
}

.product-name {
  display: flex;
  position: relative;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: end;
  gap: var(--spacing-loose);
  z-index: 2;
  padding-inline: var(--cosmos-spacing-loose, 24px);
  overflow: hidden;
  text-align: center;
}

@media (width <= 1000px) {
  [data-clicked="true"] .product-name-bg {
    margin-inline: 0;
  }
}

@media (width >= 1000px) {
  .cards:hover .product-name-bg {
    margin-inline: 0;
  }
}

.product-name-bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transition: all 0.25s linear;
  margin-inline: 24px;
  box-shadow: var(--cosmos-elevation-1-above);
  border-radius: var(--radius-x-large);
  background-color: var(--_scheme-background);
  height: 100%;
}

/*
.img-blur {
  position: absolute;
  z-index: 1;
  filter: blur(8px);
  margin-inline: auto;
  width: 240px;
  object-fit: cover;
  content: "";
}
*/

.ingredient-content {
  display: grid;
  position: relative;
  grid-template-rows: 0fr;
  z-index: 2;
  transition: grid-template-rows 500ms;
  max-width: 380px;
}

@media (width <= 1000px) {
  [data-clicked="true"] .ingredient-content {
    grid-template-rows: 1fr;
  }
}

@media (width >= 1000px) {
  .cards:hover .ingredient-content {
    grid-template-rows: 1fr;
  }
}

.ingredient-content-inner {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-loose);
  transform: translateY(50%);
  opacity: 0;
  transition:
    transform 0.25s linear,
    opacity 0.5s linear;
  padding-block: var(--spacing-xx-loose);
  overflow: hidden;
}

@media (width <= 1000px) {
  [data-clicked="true"] .ingredient-content-inner {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (width >= 1000px) {
  .cards:hover .ingredient-content-inner {
    transform: translateY(0);
    opacity: 1;
  }
}

.product-title {
  display: inline-block;
  width: 100%;
  text-align: center;
  white-space: pre-wrap;
}

.button-wrapper {
  display: none;

  @media (width >= 1000px) {
    display: flex; /* must be flex */
  }
}

.button-wrapper-mobile {
  --button-wrapper-flex-direction: column;

  @media (width >= 1000px) {
    display: none;
  }
}

.button-container {
  display: flex;
  justify-content: center;
}

.dots-container {
  position: relative;
  margin-bottom: var(--spacing-xx-loose);

  @media (width >= 1000px) {
    display: none;
  }
}
